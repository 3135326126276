<template>
  <div class="relative">
    <div
      v-if="loading"
      class="p-2"
    >
      <b-overlay
        :show="loading"
        no-wrap
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
      />
    </div>
    <b-list-group vertical>
      <b-list-group-item
        v-for="(item, index) in listData"
        :key="index"
        tag="li"
      >
        <b-row class="align-items-center">
          <b-col>
            <span v-html="item.property || item.name" />
          </b-col>
          <b-col class="d-flex justify-content-between align-items-center">
            <span v-html="item.value" />
            <span v-if="item.action && item.action[0]">
              <list-actions :button-props="item.action[0]" />
            </span>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import listWithActionsStoreModule from './listWithActionsStoreModule'
import store from '@/store'
import useListWithActions from './useListWithActions'
import ListActions from './ListActions.vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BOverlay,
    ListActions,
  },
  props: {
    api: {
      type: String,
      required: true,
    },
    apiId: {
      type: [Object, String, null],
      default: null,
    },
    xparam1: {
      type: [Object, String, null],
      required: false,
    },
    xparam2: {
      type: [Object, String, null],
      required: false,
    },
    loadByTab: {
      type: [Number, String, null],
      default: null,
    },
    currentTab: {
      type: [Number, String, null],
      default: null,
    },
    autoload: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  setup(props) {
    const STORE_MODULE_NAME = 'list-with-actions'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, listWithActionsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      loading,
      listData,

      fetchData,
    } = useListWithActions(props)

    if (props.api && props.autoload && props.loadByTab === null) {
      fetchData()
    }

    return {
      loading,
      listData,
    }
  },
}
</script>
