<template>
  <b-modal
    :id="id"
    ref="refModal"
    :title="title"
    size="md"
    hide-footer
    @hidden="disabled = false"
  >
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        :disabled="loading"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
        class="sp-modal-form-transfer"
      >
        <b-row
          class="match-height"
        >
          <b-col
            sm="12"
          >
            <search-select-input
              id="x3num"
              name="x3num"
              :value.sync="x3num"
              init-api="https://api.vestaorganic.ru/api/v1/json/lk/buyer/search/list/perenos?q=000"
              :get-option-label="option =&gt; option.FIO"
              label="Поиск партнера"
              placeholder="Номер, ФИО, телефон, email"
              search-label="Номер, ФИО, телефон, email"
              api="https://api.vestaorganic.ru/api/v1/json/lk/buyer/search/list/perenos"
            />
          </b-col>
          <b-col
            sm="12"
          >
            <number-input
              id="x1sum"
              name="x1sum"
              :value.sync="x1sum"
              :get-validation-state="getValidationState"
              rules=""
              label="Сумма, руб."
              api=""
            />
          </b-col>
          <b-col
            sm="12"
          >
            <textarea-input
              id="x4Text"
              name="x4Text"
              :value.sync="x4Text"
              rules=""
              label="Комментарий к операции"
              placeholder=""
              api=""
            />
          </b-col>
        </b-row>
        <hr
          style="margin-left:-1.4rem;margin-right:-1.4rem;"
        >
        <div class="d-flex mt-2 justify-content-end">
          <b-button
            variant="flat-danger"
            class="mr-50"
            @click="hideModal()"
          >
            Отмена
          </b-button>
          <b-button
            variant="primary"
            type="submit"
            :disabled="disabled"
          >
            <b-spinner
              v-if="loading"
              small
              class="mr-50"
            />
            <span>Перевести</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
/* eslint-disable */
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from '@axios'

import { ref, nextTick } from '@vue/composition-api'

import SearchSelectInput from '@core/components/input/search-select-input/SearchSelectInput.vue'
import NumberInput from '@core/components/input/number-input/NumberInput.vue'
import TextareaInput from '@core/components/input/textarea-input/TextareaInput.vue'

export default {
  components: {
    BModal,
    BForm,
    BRow,
    BCol,
    ValidationObserver,
    BButton,
    BSpinner,

    SearchSelectInput,
    NumberInput,
    TextareaInput,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    sendApi: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
      default: () => 'post',
    },
    getApi: {
      type: String,
      required: true,
    },
    apiId: {
      type: [Object, String, Number, null],
      required: false,
    },
    refresh: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { root, emit }) {
    const refModal = ref(null)

    const x1sum = ref('')
    const x3num = ref(null)
    const x4Text = ref('')

    const resetData = () => {
      x1sum.value = ''
      x3num.value = null
      x4Text.value = ''
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    const hideModal = () => {
      resetForm()
      refModal.value.hide()
      disabled.value = false
    }

    if (props.getApi) {
      axios.get(props.getApi)
        .then(response => {
          const { data } = response.data

          x1sum.value = data.x1sum
          x4Text.value = data.x4Text
        })
    }

    const loading = ref(false)
    const disabled = ref(false)
    const onSubmit = () => {
      disabled.value = true
      if (props.sendApi) {
        root.$swal({
          icon: 'question',
          html: `Перевести ${x1sum.value}руб. партнеру: <br>${x3num.value.query}`,
          showCancelButton: true,
          confirmButtonText: 'Перевести',
          cancelButtonText: 'Отмена',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
        }).then(result => {
          if (result.isConfirmed) {
            loading.value = true
            disabled.value = true
            const formData = {
              x1sum: x1sum.value,
              x3num: x3num.value.id || x3num.value,
              x4text: x4Text.value,
            }
            const url = props.apiId ? `${props.sendApi}/${props.apiId}` : props.sendApi
            if (props.method === 'post') {
              axios.post(url, formData)
                .then(response => {
                  const { data } = response.data

                  hideModal()

                  root.$swal({
                    icon: 'success',
                    html: data.msg,
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })

                  loading.value = false

                  emit('update:refresh', true)
                  nextTick(() => {
                    // reset refresh
                    emit('update:refresh', false)
                  })
                })
                .catch(err => {
                  const { data } = err.response.data

                  root.$swal({
                    icon: 'error',
                    html: data.msg,
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                  loading.value = false
                })
            }

            if (props.method === 'put') {
              axios.put(url, formData)
                .then(response => {
                  const { data } = response.data

                  root.$swal({
                    icon: 'success',
                    text: data.msg,
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })

                  loading.value = false

                  emit('update:refresh', true)
                  nextTick(() => {
                    // reset refresh
                    emit('update:refresh', false)
                  })
                })
                .catch(() => {
                  loading.value = false
                })
            }
          }
        })
      }
    }

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      onSubmit,
      loading,
      disabled,
      x1sum,
      x3num,
      x4Text,
      refModal,
      hideModal,
    }
  },
}
</script>
<style>
.sp-modal-form-transfer .vs__dropdown-menu {
  max-height: 220px;
}
</style>
