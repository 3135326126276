import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default function useListWithActions(props) {
  const refList = ref(null)
  const listData = ref(null)
  const loading = ref(false)

  const fetchData = () => {
    loading.value = true
    // eslint-disable-next-line prefer-destructuring
    const xparam1 = props.xparam1
    // eslint-disable-next-line prefer-destructuring
    const xparam2 = props.xparam2
    store.dispatch('list-with-actions/fetchData', {
      api: props.api,
      apiId: props.apiId,
      params: {
        xparam1,
        xparam2,
      },
    })
      .then(response => {
        const { data } = response.data
        listData.value = data
        loading.value = false
      })
      .catch(() => {
        listData.value = null
        loading.value = false
      })
  }

  watch(() => props.apiId, () => {
    if (props.loadByTab === null || (props.currentTab === props.loadByTab)) {
      fetchData()
    }
  })

  watch(() => props.xparam1, () => {
    fetchData()
  })

  watch(() => props.xparam2, () => {
    fetchData()
  })

  watch(() => props.currentTab, () => {
    if (props.currentTab === props.loadByTab && props.apiId) {
      fetchData()
    }
  })

  return {
    refList,
    listData,
    loading,

    fetchData,
  }
}
