<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>{{ title }}</b-card-title>
    </b-card-header>
    <b-card-body>
      <div
        v-for="(item, key) in d"
        :key="key"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside
            v-if="item.avatar"
          >
            <b-avatar
              rounded
              size="42"
              :variant="item.avatarVariant"
            >
              <feather-icon
                size="18"
                :icon="item.avatar"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ item.mode }}
            </h6>
            <small>{{ item.types }}</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :class="item.color"
        >
          {{ item.payment }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: () => '',
    },
    refresh: {
      type: Boolean,
      required: false,
    },
    autoload: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    loadByTab: {
      type: [Number, String, null],
      default: null,
    },
    currentTab: {
      type: [Number, String, null],
      default: null,
    },
    api: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const d = ref(JSON.parse(JSON.stringify(props.data || {})))
    const loading = ref(false)

    const fetchData = () => {
      loading.value = true
      store.dispatch('app/fetchData', props.api).then(res => {
        const { data, success } = res.data

        if (success) {
          d.value = data
        }

        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }

    if (props.api) {
      if (props.loadByTab) {
        if (props.currentTab === props.loadByTab) {
          fetchData()
        }
      } else {
        fetchData()
      }
    }

    watch(() => props.currentTab, () => {
      if (props.currentTab === props.loadByTab) {
        fetchData()
      }
    })

    watch(() => props.refresh, () => {
      if (props.refresh) {
        fetchData()
      }
    })

    return {
      d,
      loading,
    }
  },
}
</script>
