<template>
  <b-card
    :title="d.title"
    class="text-center"
    no-body
  >
    <b-card-body>
      <b-card-text>
        {{ d.comment }}
      </b-card-text>
    </b-card-body>
    <b-card-footer v-if="modalId">
      <b-button
        v-b-modal="modalId"
        :variant="`outline-${d.colorBtn}`"
        :disabled="d.colorBtn === 'secondary'"
      >
        {{ d.label || label }}
      </b-button>
    </b-card-footer>
  </b-card>
</template>

<script>
/* eslint-disable */
import {
  BCard, BCardBody, BCardText, BButton, BCardFooter,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BCard, BCardBody, BCardText, BButton, BCardFooter,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    modalId: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
    refresh: {
      type: Boolean,
      required: false,
    },
    label: {
      type: String,
      default: () => '',
    },
    api: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const d = ref(JSON.parse(JSON.stringify(props.data || {})))
    const loading = ref(false)

    const fetchData = () => {
      loading.value = true
      store.dispatch('app/fetchData', props.api).then(res => {
        const { data, success } = res.data

        if (success) {
          d.value = data[0]
        }

        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }

    if (props.api) {
      fetchData()

      watch(() => props.refresh, () => {
        if (props.refresh) {
          fetchData()
        }
      })
    }

    return {
      d,
      loading,
    }
  },
}
</script>
