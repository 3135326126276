        <template>
  <!-- Container Card -->
  <b-card>
    <b-row>
        </b-row>
        <b-tabs
      ref="my-tabs"
      v-model="currentTab"
      pills
      nav-class="scrollable-tabs-nav"
    >
              <b-tab
                              title-item-class="text-nowrap"
        >
          <template #title>
            <feather-icon
              icon="AlignJustifyIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-sm-inline">Личный счет</span>
          </template>
          <div>
            <b-row
                              class="match-height"
                          >
                                                                    <b-col
                                                                                                                                      sm="3"
                                                                >
                                            <card-list
  :data="null"
  title="Состояние счетов"
    :refresh="refresh"
    api="https://api.vestaorganic.ru/api/v1/json/lk/buyer/postuplenia/page/acc"
      />
                    </b-col>
                                                                        <b-col
                                                                                                                                      sm="3"
                                                                >
                                            <profit-chart
  :data="null"
  title="Всего"
    :refresh="refresh"
    api="https://api.vestaorganic.ru/api/v1/json/lk/buyer/postuplenia/page/chart"
/>                    </b-col>
                                                                        <b-col
                                                                                                                                      sm="3"
                                                                >
                                            <card-text-button
  :data="null"
    modal-id="sp-modal-form-transfer"
    title="Запрос на перевод средств"
    :refresh="refresh"
    label="Перевести со счета"
  api="https://api.vestaorganic.ru/api/v1/json/lk/buyer/postuplenia/page/perenos"
/>                    </b-col>
                                                                        <b-col
                                                                                                                                      sm="4"
                                                                >
                                            <select-input
  id="account"
  name="account"
    :value.sync="xparam1"
        :options="[]"
      :getOptionLabel="option =&gt; option.text"
      :reduce="val =&gt; val.value"
                label="Счет"
  api="https://api.vestaorganic.ru/api/v1/json/lk/service/data/list/account"
  />
                    </b-col>
                                                                        <b-col
                                                                                                                                      sm="4"
                                                                >
                                            <select-input
  id="typeoper"
  name="typeoper"
    :value.sync="xparam2"
        :options="[]"
      :getOptionLabel="option =&gt; option.text"
      :reduce="val =&gt; val.value"
                label="Тип начисления"
  api="https://api.vestaorganic.ru/api/v1/json/lk/service/data/list/typeoper"
  />
                    </b-col>
                                                                        <b-col
                                                                                                                                      sm="12"
                                                                >
                                            <basic-table
  :columns="[{&quot;key&quot;:&quot;BB_date&quot;,&quot;label&quot;:&quot;\u0414\u0430\u0442\u0430 (\u0432\u0440\u0435\u043c\u044f)&quot;,&quot;type&quot;:&quot;text&quot;,&quot;sortable&quot;:false,&quot;width&quot;:&quot;10&quot;,&quot;sort&quot;:&quot;1&quot;,&quot;thClass&quot;:&quot;text-center&quot;,&quot;tdClass&quot;:&quot;text-center&quot;},{&quot;key&quot;:&quot;BB_sType&quot;,&quot;label&quot;:&quot;\u041e\u043f\u0435\u0440\u0430\u0446\u0438\u044f&quot;,&quot;type&quot;:&quot;text&quot;,&quot;sortable&quot;:false,&quot;width&quot;:&quot;10&quot;,&quot;sort&quot;:&quot;2&quot;,&quot;thClass&quot;:&quot;text-center&quot;},{&quot;key&quot;:&quot;BB_summ&quot;,&quot;label&quot;:&quot;\u0421\u0443\u043c\u043c\u0430,\u0440\u0443\u0431&quot;,&quot;type&quot;:&quot;float&quot;,&quot;sortable&quot;:true,&quot;width&quot;:&quot;10&quot;,&quot;sort&quot;:&quot;3&quot;,&quot;thClass&quot;:&quot;text-center&quot;,&quot;tdClass&quot;:&quot;text-right&quot;},{&quot;key&quot;:&quot;BB_Comment&quot;,&quot;label&quot;:&quot;\u041a\u043e\u043c\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0439&quot;,&quot;type&quot;:&quot;text&quot;,&quot;sortable&quot;:false,&quot;width&quot;:&quot;50&quot;,&quot;sort&quot;:&quot;4&quot;,&quot;thClass&quot;:&quot;text-center&quot;},{&quot;key&quot;:&quot;BB_icon&quot;,&quot;label&quot;:&quot;&quot;,&quot;type&quot;:&quot;icon&quot;,&quot;sortable&quot;:false,&quot;width&quot;:&quot;5&quot;,&quot;sort&quot;:&quot;5&quot;,&quot;thClass&quot;:&quot;text-center&quot;}]"
          :refresh="refresh"
            :xparam1="xparam1"
      :xparam2="xparam2"
                                            api="https://api.vestaorganic.ru/api/v1/json/lk/buyer/postuplenia/xgrid"
    :loadByTab="0"
      :currentTab="currentTab"
      :autoload="true"
      />
                    </b-col>
                                                                        <b-col
                                                                                                                                                        >
                                            <sp-modal-form-tryout
    sendApi="https://api.vestaorganic.ru/api/v1/json/lk/buyer/postuplenia/add/tryout"
    method="post"
    getApi="https://api.vestaorganic.ru/api/v1/json/lk/buyer/postuplenia/item/out"
    id="sp-modal-form-tryout"
    title="Запрос на вывод средств"
            :refresh="refresh"
    />
                    </b-col>
                                                                        <b-col
                                                                                                                                                        >
                                            <sp-modal-form-transfer
    sendApi="https://api.vestaorganic.ru/api/v1/json/lk/buyer/postuplenia/add/perenos"
    method="post"
    getApi="https://api.vestaorganic.ru/api/v1/json/lk/buyer/search/list/perenos"
    id="sp-modal-form-transfer"
    title="Запрос на перевод средств"
        :refresh.sync="refresh"
/>
                    </b-col>
                                                            </b-row>
          </div>
        </b-tab>
              <b-tab
                              title-item-class="text-nowrap"
        >
          <template #title>
            <feather-icon
              icon="CheckSquareIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-sm-inline">Начисления</span>
          </template>
          <div>
            <b-row
                          >
                                                                    <b-col
                                                                                                                                      sm="5"
                                                                >
                                            <select-input
  id="cp"
  name="cp"
    :value.sync="cp"
        :options="[]"
      :getOptionLabel="option =&gt; option.text"
      :reduce="val =&gt; val.value"
                label="Список периодов"
  api="https://api.vestaorganic.ru/api/v1/json/lk/buyer/cp/list/cp"
  />
                    </b-col>
                                                                        <b-col
                                                                                                                                      sm="12"
                                                                >
                                            <list-with-actions
        :apiId="cp"
    api="https://api.vestaorganic.ru/api/v1/json/lk/buyer/cp/list"
      :loadByTab="1"
      :currentTab="currentTab"
      :autoload="false"
  />                    </b-col>
                                                            </b-row>
          </div>
        </b-tab>
          </b-tabs>
      </b-card>
</template>

<script>
/* eslint-disable */
// prettier-ignore
import {
  BTab, BTabs, BCard, BRow,
  BCol, VBModal,
} from 'bootstrap-vue'

import { ref, watch } from '@vue/composition-api'


                                                                                import CardList from '@core/components/dashboard/CardList.vue'
                                                                                                                          import ProfitChart from '@core/components/dashboard/ProfitChart.vue'
                                                                                                                          import CardTextButton from '@core/components/dashboard/CardTextButton.vue'
                                                                                                                          import SelectInput from '@core/components/input/select-input/SelectInput.vue'
                                                                                                                                                                              import BasicTable from '@core/components/table/basic-table/BasicTable.vue'
                                                                                                                          import SpModalFormTryout from '@core/components/sp/sp-modal-form/SpModalFormTryout.vue'
                                                                                                                          import SpModalFormTransfer from '@core/components/sp/sp-modal-form/SpModalFormTransfer.vue'
                                                                                                                                                                                                import ListWithActions from '@core/components/list/list-with-actions/ListWithActions.vue'
                                                                
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
                                                                                                CardList,
                                                                                                              ProfitChart,
                                                                                                              CardTextButton,
                                                                                                              SelectInput,
                                                                                                                                                              BasicTable,
                                                                                                              SpModalFormTryout,
                                                                                                              SpModalFormTransfer,
                                                                                                                                                                                              ListWithActions,
                                                                        },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      isMouseDown: false,
      startX: null,
      scrollLeft: null,
      navElement: null,
    }
  },
  mounted() {
    this.navElement = this.$refs['my-tabs'].$el.querySelector('ul')

    this.navElement.addEventListener('mousedown', this.onMouseDown)
    this.navElement.addEventListener('mouseup', this.onMouseUp)
    this.navElement.addEventListener('mousemove', this.onMouseMove)
    this.navElement.addEventListener('mouseleave', this.onMouseLeave)

    this.navElement.addEventListener('touchstart', this.onTouchStart)
    this.navElement.addEventListener('touchmove', this.onTouchMove)
  },
  onBeforeDestroy() {
    this.navElement.removeEventListener('mousedown', this.onMouseDown)
    this.navElement.removeEventListener('mouseup', this.onMouseUp)
    this.navElement.removeEventListener('mousemove', this.onMouseMove)
    this.navElement.removeEventListener('mouseleave', this.onMouseLeave)

    this.navElement.removeEventListener('touchstart', this.onMouseDown)
    this.navElement.removeEventListener('touchmove', this.onMouseMove)
  },
  methods: {
    // Scrolling tabs methods
    onTouchStart(event) {
      event.stopPropagation()
      this.startX = event.touches[0].pageX - this.navElement.offsetLeft
      this.scrollLeft = this.navElement.scrollLeft
    },
    onTouchMove(event) {
      event.stopPropagation()
      event.preventDefault()
      const x = event.touches[0].pageX - this.navElement.offsetLeft
      const speed = 1 // higher number means faster scrolling
      const walk = (x - this.startX) * speed
      this.navElement.scrollLeft = this.scrollLeft - walk
    },
    onMouseDown(event) {
      event.stopPropagation()
      event.preventDefault()
      this.isMouseDown = true

      this.startX = event.pageX - this.navElement.offsetLeft
      this.scrollLeft = this.navElement.scrollLeft
    },
    onMouseUp(event) {
      event.stopPropagation()
      event.preventDefault()
      this.isMouseDown = false
    },
    onMouseLeave(event) {
      event.stopPropagation()
      event.preventDefault()
      this.isMouseDown = false
    },
    onMouseMove(event) {
      if (!this.isMouseDown) return

      event.stopPropagation()
      event.preventDefault()
      const x = event.pageX - this.navElement.offsetLeft
      const speed = 1.5 // higher number means faster scrolling
      const walk = (x - this.startX) * speed
      this.navElement.scrollLeft = this.scrollLeft - walk
    },
  },
  setup() {
                const xparam1 = ref('1')
            const xparam2 = ref('0')
            const cp = ref('0')
            const currentTab = ref()
            const refresh = ref(false)
          
    return {
                          xparam1,
                  xparam2,
                  cp,
                  currentTab,
                  refresh,
                  }
  },
}
</script>
<style lang="scss">
.scrollable-tabs-nav {
  flex-wrap: nowrap;
  overflow: hidden;
  padding-bottom: 1rem;
}
</style>
    