<template>
  <div v-if="buttonProps">
    <BButton
      v-if="buttonProps.type === 'modalGrid'"
      variant="primary"
      :class="buttonProps.color"
      size="sm"
      @click="fetchGridData()"
    >
      {{ buttonProps.title }}
    </BButton>
    <BButton
      v-if="buttonProps.sType === 'setBlock'"
      :variant="buttonProps.color"
      size="sm"
      @click="setBlock"
    >
      {{ buttonProps.title }}
    </BButton>
    <BButton
      v-if="buttonProps.sType === 'sendEmail'"
      :variant="buttonProps.color"
      size="sm"
      @click="sendEmail()"
    >
      {{ buttonProps.title }}
    </BButton>
    <b-modal
      v-if="buttonProps.type === 'modalGrid'"
      v-model="modalGrid"
      :modal-class="`modal-${buttonProps.color}`"
      :title="buttonProps.modalConfig.title || buttonProps.title"
      size="xl"
      hide-footer
    >
      <div>
        <div
          v-if="loading"
          class="p-2"
        >
          <b-overlay
            :show="loading"
            no-wrap
            :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
          />
        </div>
        <div
          v-else
        >
          <b-table
            :items="gridData || []"
            :fields="gridFields || []"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton,
  BTable,
  BOverlay,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BButton,
    BTable,
    BOverlay,
  },
  props: {
    apiId: {
      type: [Object, String, null],
      required: false,
    },
    buttonProps: {
      type: Object,
      required: false,
      default: null,
    },
  },
  methods: {
    sendEmail() {
      store.dispatch('app/buyerWork', {
        id_buyer: this.apiId,
        stype: 'sendEmail',
      })
    },
    setBlock() {
      store.dispatch('app/buyerWork', {
        id_buyer: this.apiId,
        x1param: this.buttonProps.xparam1,
        stype: 'setBlock',
      }).then(res => {
        if (res) {
          this.$emit('blocked')
        }
      })
    },
  },
  setup(props) {
    const loading = ref(false)
    const modalGrid = ref(false)
    const gridData = ref(null)
    const gridFields = ref(null)

    const fetchGridData = () => {
      modalGrid.value = true
      loading.value = true
      store.dispatch('list-with-actions/fetchData', {
        api: props.buttonProps.modalConfig.api,
        params: {
          xparam1: props.buttonProps.xparam1,
        },
      })
        .then(response => {
          const { data } = response.data
          gridData.value = data.rows
          gridFields.value = data.fields
          loading.value = false
        })
        .catch(() => {
          gridData.value = null
          gridFields.value = null
          modalGrid.value = false
          loading.value = false
        })
    }

    return {
      loading,
      modalGrid,
      gridData,
      gridFields,
      fetchGridData,
    }
  },
}
</script>
