<template>
  <b-modal
    :id="id"
    ref="refModal"
    :title="title"
    size="sm"
    hide-footer
  >
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        :disabled="loading"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row
          class="match-height"
        >
          <b-col
            sm="12"
          >
            <number-input
              id="x1sum"
              name="x1sum"
              :value.sync="x1sum"
              :get-validation-state="getValidationState"
              label="Сумма"
              api=""
            />                </b-col>
          <b-col
            sm="12"
          >
            <select-input
              id="x2Type"
              name="x2Type"
              :value.sync="x2Type"
              :get-option-label="option =&gt; option.text"
              :reduce="val =&gt; val.value"
              label="Платежная система"
              api="https://api.vestaorganic.ru/api/v1/json/lk/service/data/list/payOutSystem"
            />                </b-col>
          <b-col
            sm="12"
          >
            <text-input
              id="x3num"
              name="x3num"
              :value.sync="x3num"
              :get-validation-state="getValidationState"
              label="Номер счета/карты"
              api=""
            />                </b-col>
          <b-col
            sm="12"
          >
            <textarea-input
              id="x4Text"
              name="x4Text"
              :value.sync="x4Text"
              :get-validation-state="getValidationState"
              label="Подробные реквизиты"
              placeholder="Наименование организации или ФИО
ИНН (ИП)
Расч. счет
Наименование банка
Кор. счет
БИК"
              api=""
            />                </b-col>
        </b-row>
        <hr
          style="margin-left:-1.4rem;margin-right:-1.4rem;"
        >
        <div class="d-flex mt-2 justify-content-end">
          <b-button
            variant="flat-danger"
            class="mr-50"
            @click="hideModal()"
          >
            Отмена
          </b-button>
          <b-button
            variant="primary"
            type="submit"
            :disabled="loading"
          >
            <b-spinner
              v-if="loading"
              small
              class="mr-50"
            />
            <span>Подать заявку</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from '@axios'

import { ref,nextTick } from '@vue/composition-api'

                                      import NumberInput from '@core/components/input/number-input/NumberInput.vue'
                                                              import SelectInput from '@core/components/input/select-input/SelectInput.vue'
                                                              import TextInput from '@core/components/input/text-input/TextInput.vue'
                                                              import TextareaInput from '@core/components/input/textarea-input/TextareaInput.vue'

export default {
  components: {
    BModal,
    BForm,
    BRow,
    BCol,
    ValidationObserver,
    BButton,
    BSpinner,

                                                          NumberInput,
                                                                              SelectInput,
                                                                              TextInput,
                                                                              TextareaInput,
                                          },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    sendApi: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
      default: () => 'post',
    },
    getApi: {
      type: String,
      required: true,
    },
    apiId: {
      type: [Object, String, Number, null],
      required: false,
    },
    refresh: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { root, emit }) {
    const refModal = ref(null)

                const x1sum = ref('0')
            const x2Type = ref('0')
            const x3num = ref('')
            const x4Text = ref('')

    const resetData = () => {
            x1sum.value = ''
            x2Type.value = ''
            x3num.value = ''
            x4Text.value = ''
          }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    const hideModal = () => {
      resetForm()
      refModal.value.hide()
    }

    if (props.getApi) {
      axios.get(props.getApi)
        .then(response => {
          const { data } = response.data

                    x1sum.value = data['x1sum']
                    x2Type.value = data['x2Type']
                    x3num.value = data['x3num']
                    x4Text.value = data['x4Text']
                  })
    }

    const loading = ref(false)
    const onSubmit = () => {
      loading.value = true
      const formData = {
                x1sum: x1sum.value,
                x2type: x2Type.value,
                x3num: x3num.value,
                x4text: x4Text.value,
              }

      if (props.sendApi) {
        const url = props.apiId ? `${props.sendApi}/${props.apiId}` : props.sendApi
        if (props.method === 'post') {
          axios.post(url, formData)
            .then(response => {
              const { data } = response.data

              hideModal()

              root.$swal({
                icon: 'success',
                html: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              loading.value = false

              emit('update:refresh', true)
              nextTick(() => {
                // reset refresh
                emit('update:refresh', false)
              })
            })
            .catch(err => {
              const { data } = err.response.data

              root.$swal({
                icon: 'error',
                html: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              loading.value = false
            })
        }

        if (props.method === 'put') {
          axios.put(url, formData)
            .then(response => {
              const { data } = response.data

              root.$swal({
                icon: 'success',
                text: data.msg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              loading.value = false

              emit('update:refresh', true)
              nextTick(() => {
                // reset refresh
                emit('update:refresh', false)
              })
            })
            .catch(() => {
              loading.value = false
            })
        }
      }
    }

          return {
        refFormObserver,
        getValidationState,
        resetForm,
        onSubmit,
        loading,
                x1sum,
                x2Type,
                x3num,
                x4Text,
                refModal,
        hideModal,
      }
      },
}
</script>
